<template>
  <div class="flex justify-center items-center min-h-screen-action-bar -mt-14 w-full">
    <Card content>
      <form class="w-66 py-4 px-3" @submit.prevent="goToStudioUrl">
        <TextField
          v-model="studio"
          :label="$t('inputStudioName')"
          required
          floating
        />
        <Button type="submit" primary class="w-full">
          {{ $t('continue') }}
        </Button>
      </form>
    </Card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      studio: null,
    };
  },

  methods: {
    goToStudioUrl() {
      // Try to go to studio url.
      // If not authenticated, the user will be redirected to the login from the 'apps' route.
      this.$router.push({ name: 'apps', params: { studio: this.studio.toLowerCase() } })
        // Suppress the error of more than one redirect. See more details here:
        // https://stackoverflow.com/questions/62223195/vue-router-uncaught-in-promise-error-redirected-from-login-to-via-a
        .catch(() => {});
    },
  },
};
</script>
